var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            "footer-class": "footerClass",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
            scrollable: "",
          },
          on: { shown: _vm.uploadDialogOpen, hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "b-button",
                          {
                            staticClass: "history-button",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                _vm.historyShow = true
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "clock-rotate-left"] },
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("button.history")) + " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.allowManage && (_vm.canEdit() || !_vm.exists)
                    ? [
                        _vm.isSubmitting
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: "",
                                },
                              },
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", type: "grow" },
                                }),
                                _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                              ],
                              1
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: { size: "sm", variant: "success" },
                                on: { click: _vm.ok },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.ok")))]
                            ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "SELECT" === _vm.mode
                              ? "button.close"
                              : "button.cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _c("span", { staticClass: "pl-2" }, [
                _vm._v(_vm._s(_vm.alertMsg)),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container pl-0" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("file.field.parent_folder"),
                            "label-for": "parent",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "parent",
                                  type: "text",
                                  size: "sm",
                                  readonly: true,
                                },
                                model: {
                                  value: _vm.parent.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.parent, "name", $$v)
                                  },
                                  expression: "parent.name",
                                },
                              }),
                              !_vm.isNameReadOnly
                                ? _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            size: "sm",
                                            variant: "info",
                                          },
                                          on: { click: _vm.parentSelectorOpen },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("button.select"))
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.customFieldMap["parentFolder"] != null
                    ? _vm._l(
                        _vm.customFieldMap["parentFolder"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "parentFolder" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isNameVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", md: "8" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("file.field.name"),
                                "label-for": "name",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: { required: true },
                                        expression: "{ required: true }",
                                      },
                                    ],
                                    attrs: {
                                      id: "name",
                                      type: "text",
                                      "data-vv-as": _vm.$t("file.field.name"),
                                      "data-vv-name": "name",
                                      "data-vv-delay": "500",
                                      readonly: _vm.isNameReadOnly,
                                      disabled: _vm.file.length > 1,
                                      autofocus: "",
                                      state:
                                        _vm.fieldValidateUtil.stateValidate(
                                          _vm.isReadOnly,
                                          _vm.veeFields,
                                          _vm.errors,
                                          "name"
                                        ),
                                      trim: "",
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        return _vm.keydownHandler.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.name,
                                      callback: function ($$v) {
                                        _vm.name = $$v
                                      },
                                      expression: "name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-invalid-feedback",
                                {
                                  staticClass: "alert-danger form-field-alert",
                                  class: { "d-block": _vm.showNameError },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["far", "circle-exclamation"],
                                    },
                                  }),
                                  _vm._v(
                                    "  " +
                                      _vm._s(_vm.errors.first("name")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["name"] != null
                    ? _vm._l(
                        _vm.customFieldMap["name"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "name" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isIdentifierVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("field.identifier"),
                                "label-for": "identifier",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "identifier",
                                      type: "text",
                                      "data-vv-as": _vm.$t("field.identifier"),
                                      "data-vv-name": "identifier",
                                      maxlength: _vm.maxIdentifierLength,
                                      disabled: _vm.isIdentifierReadOnly,
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.identifier,
                                      callback: function ($$v) {
                                        _vm.identifier = $$v
                                      },
                                      expression: "identifier",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["identifier"] != null
                    ? _vm._l(
                        _vm.customFieldMap["identifier"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "identifier" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.customFieldMap["default"] != null
                    ? _vm._l(
                        _vm.customFieldMap["default"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isDescriptionVisible
                    ? _c(
                        "b-col",
                        {
                          staticClass: "file-details pr-0",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("file.field.description"),
                                "label-for": "desc",
                              },
                            },
                            [
                              _c("b-textarea", {
                                staticClass: "rounded-0",
                                attrs: {
                                  id: "desc",
                                  readonly: _vm.isDescriptionReadOnly,
                                  trim: "",
                                  disabled: _vm.file.length > 1,
                                },
                                model: {
                                  value: _vm.desc,
                                  callback: function ($$v) {
                                    _vm.desc = $$v
                                  },
                                  expression: "desc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["description"] != null
                    ? _vm._l(
                        _vm.customFieldMap["description"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "description" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isTagVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", md: "8" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("TagList", {
                                attrs: {
                                  readOnly: _vm.isTagReadOnly,
                                  holderId: _vm.id,
                                  tags: _vm.tags,
                                },
                                on: { modified: _vm.tagsModified },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["tags"] != null
                    ? _vm._l(
                        _vm.customFieldMap["tags"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "tags" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isColorVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "div",
                            { staticClass: "color-container" },
                            [
                              _c("Color", {
                                attrs: {
                                  disabled: _vm.isColorReadOnly,
                                  update: _vm.updatedColor,
                                },
                                model: {
                                  value: _vm.color,
                                  callback: function ($$v) {
                                    _vm.color = $$v
                                  },
                                  expression: "color",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.customFieldMap["color"] != null
                    ? _vm._l(
                        _vm.customFieldMap["color"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "color" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("file.field.file"),
                            "label-for": "uploadfile",
                          },
                        },
                        [
                          _vm.isReadOnly
                            ? _c(
                                "b-input-group",
                                { staticClass: "file-group" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "rounded-0 border-0",
                                    attrs: { readonly: "" },
                                    model: {
                                      value: _vm.labelFilename,
                                      callback: function ($$v) {
                                        _vm.labelFilename = $$v
                                      },
                                      expression: "labelFilename",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "b-input-group",
                                {
                                  staticClass: "file-edit-group",
                                  on: { click: _vm.fileChange },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "file-edit-icon",
                                    attrs: { icon: ["far", "file-arrow-up"] },
                                  }),
                                  _c("b-form-input", {
                                    staticClass:
                                      "rounded-0 form-input-readonly border-0 cursor-pointer",
                                    attrs: { readonly: "" },
                                    model: {
                                      value: _vm.labelFilename,
                                      callback: function ($$v) {
                                        _vm.labelFilename = $$v
                                      },
                                      expression: "labelFilename",
                                    },
                                  }),
                                ],
                                1
                              ),
                          _c("b-form-file", {
                            ref: "uploadfile",
                            staticClass: "d-none",
                            attrs: {
                              id: "uploadfile_" + _vm.id,
                              multiple: "",
                              plain: "",
                              accept: "*/*",
                            },
                            model: {
                              value: _vm.file,
                              callback: function ($$v) {
                                _vm.file = $$v
                              },
                              expression: "file",
                            },
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: { "d-block": _vm.showFileError },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " + _vm._s(_vm.errors.first("file")) + " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.type
                    ? _c("b-col", { attrs: { cols: "2" } }, [
                        _vm._v(_vm._s(_vm.$t("file.field.type"))),
                      ])
                    : _vm._e(),
                  _vm.type
                    ? _c("b-col", { attrs: { cols: "9" } }, [
                        _vm._v(_vm._s(_vm.type)),
                      ])
                    : _vm._e(),
                  _vm.size
                    ? _c("b-col", { attrs: { cols: "2" } }, [
                        _vm._v(_vm._s(_vm.$t("file.field.size"))),
                      ])
                    : _vm._e(),
                  _vm.size
                    ? _c("b-col", { attrs: { cols: "9" } }, [
                        _vm._v(_vm._s(_vm._f("byteFormat")(_vm.size))),
                      ])
                    : _vm._e(),
                  _c("b-col", { attrs: { cols: "2" } }, [
                    _vm._v(_vm._s(_vm.$t("file.field.created"))),
                  ]),
                  _c("b-col", { attrs: { cols: "9" } }, [
                    _vm._v(_vm._s(_vm.created)),
                  ]),
                  _c("b-col", { attrs: { cols: "2" } }, [
                    _vm._v(_vm._s(_vm.$t("file.field.modified"))),
                  ]),
                  _c("b-col", { attrs: { cols: "9" } }, [
                    _vm._v(_vm._s(_vm.modified)),
                  ]),
                  _vm.customFieldMap["file"] != null
                    ? _vm._l(
                        _vm.customFieldMap["file"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "file" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isNoteVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("NoteList", {
                                staticClass: "mt-2",
                                attrs: {
                                  readOnly: _vm.isNoteReadOnly,
                                  notes: _vm.notes,
                                },
                                on: {
                                  add: _vm.addNote,
                                  edit: _vm.editNote,
                                  toRemove: _vm.removeNote,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["notes"] != null
                    ? _vm._l(
                        _vm.customFieldMap["notes"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "notes" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("FolderSelectorModal", {
        attrs: { currentPath: _vm.currentPath, show: _vm.folderSelectorShow },
        on: {
          "update:show": function ($event) {
            _vm.folderSelectorShow = $event
          },
          success: _vm.folderSelectorSuccess,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Upload",
            centered: "",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
            "hide-footer": "",
          },
          model: {
            value: _vm.uploadProgressShow,
            callback: function ($$v) {
              _vm.uploadProgressShow = $$v
            },
            expression: "uploadProgressShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                dismissible: "",
                fade: "",
                show: _vm.uploadSuccessShow,
                variant: "success",
              },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: ["far", "check"] } }),
              _vm._v("  " + _vm._s(_vm.uploadSuccessMsg) + " "),
            ],
            1
          ),
          _c(
            "b-alert",
            {
              attrs: {
                dismissible: "",
                fade: "",
                show: _vm.uploadAlertShow,
                variant: "danger",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertUploadMsg) + " "),
            ],
            1
          ),
          _c(
            "b-progress",
            { attrs: { max: 100, height: "2rem" } },
            [
              _c("b-progress-bar", {
                attrs: {
                  value: _vm.uploadPercentage,
                  label: _vm.uploadPercentage.toFixed(0) + "%",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mt-2",
                  attrs: {
                    variant:
                      !_vm.uploadSuccessShow && !_vm.uploadAlertShow
                        ? "secondary"
                        : _vm.uploadSuccessShow
                        ? "success"
                        : "danger",
                  },
                  on: { click: _vm.uploadProcessClick },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          !_vm.uploadSuccessShow && !_vm.uploadAlertShow
                            ? "button.cancel"
                            : _vm.uploadSuccessShow
                            ? "button.done"
                            : "button.failed"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.exists
        ? [
            _c("GenericHistoryModal", {
              attrs: {
                show: _vm.historyShow,
                id: _vm.id,
                entityType: "FILE",
                customFields: _vm.customFields,
                links: "NOTE,TAG",
              },
              on: {
                "update:show": function ($event) {
                  _vm.historyShow = $event
                },
              },
            }),
            _vm.noteShow
              ? _c("NoteModal", {
                  attrs: { show: _vm.noteShow, note: _vm.note },
                  on: {
                    "update:show": function ($event) {
                      _vm.noteShow = $event
                    },
                    toAdd: _vm.toAddNote,
                    toUpdate: _vm.toUpdateNote,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }